<script setup>
import { reactive } from 'vue'
import { useSidePanel } from '@/stores/sidepanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields'

const sidePanelStore = useSidePanel()

const items = reactive([
  {
    label: 'Settings',
    icon: 'file-pen',
    tab: 'settings'
  },
  {
    label: 'Users',
    icon: 'user',
    tab: 'users'
  },
  {
    label: 'Activities',
    icon: 'history',
    tab: 'activities'
  }
])

const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const object = EntityComputedFields.useEntityComputedFields({
  refId: refId,
  store: storeName,
  type: type
})

const { company_name, company_id } = object

const actions = c.getActions('company')
</script>

<template>
  <div class="w-full h-screen flex flex-col">
    <div
      class="md:hidden px-4 h-[60px] w-full m-0 flex flex-row justify-between items-center border-b border-cement-600"
    >
      <span class="text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>

    <div class="flex flex-col items-center h-24 justify-center border-cool-gray-200 border-b">
      <PersonAvatar
        class="mb-1"
        :id="company_id"
        type="company"
        :name="company_name"
        :onClick="() => {}"
      />
      <span class="flex items-center">
        <span>{{ $f.truncate(company_name, 30) }}</span>
        <BtnBar
          class="ml-2"
          :selected="[object]"
          v-if="actions"
          :collapse="true"
          type="company"
          :actions="actions"
        />
      </span>
    </div>

    <SidePanelMenu class="mt-4" :model="items" :allowStarred="false" />
  </div>
</template>
